import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../Particle";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Coming <strong className="purple">soon... </strong>
        </h1>
        <p style={{ color: "white" }}>
          Em breve implementaremos nosso Marketplace!
        </p>
        {/* <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
  
        </Row> */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        
      </Container>
    </Container>
  );
}

export default Projects;
