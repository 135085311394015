import React from "react";
import Card from "react-bootstrap/Card";
import { ImPlay3 } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Somos 4 estudantes de <span className="purple">engenharia de computação </span>
            do <span className="purple"> Insper</span> em São Paulo, SP.
          </p>
          <ul>
            <li className="about-activity">
              <ImPlay3 style={{color: '#C372F1'}}/> Arthur Tamm
            </li>
            <li className="about-activity">
              <ImPlay3 style={{color: '#C372F1'}}/> Caio Bôa
            </li>
            <li className="about-activity">
              <ImPlay3 style={{color: '#C372F1'}}/> Gabriel Hermida
            </li>
            <li className="about-activity">
              <ImPlay3 style={{color: '#C372F1'}} /> Pedro Civita
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
